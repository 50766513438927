import { useEffect, useState } from 'react';

import {
  HeaderComponent,
  BannerSectionComponent,
  FAQSectionComponent,
  SceneSectionComponent,
  WhatTagLiveLabelSectionComponent,
  EasyToIntroduceSectionComponent,
  FooterComponent,
  OverviewServiceSectionComponent,
  SEOComponent,
  FloatButtonSectionComponent,
  CustomersSectionComponent,
  FloatAsakusaEventSectionComponent,
} from '@/components';
import { isCurrentDateBetween } from '@/utils/datetime';

const Home = () => {
  const [isDuringAsakusaEvent, setIsDuringAsakusaEvent] = useState(false);

  useEffect(() => {
    const isDuringAsakusaEvent = isCurrentDateBetween(
      new Date(process.env.NEXT_PUBLIC_ASAKUSA_EVENT_START as string),
      new Date(process.env.NEXT_PUBLIC_ASAKUSA_EVENT_END as string)
    );
    setIsDuringAsakusaEvent(isDuringAsakusaEvent);
  }, []);

  return (
    <>
      <SEOComponent screenName="top" />
      <main>
        <HeaderComponent />
        <BannerSectionComponent />
        <OverviewServiceSectionComponent />
        <WhatTagLiveLabelSectionComponent />
        <SceneSectionComponent />
        <CustomersSectionComponent />
        <EasyToIntroduceSectionComponent />
        <FAQSectionComponent />
        <FooterComponent withSocial isInTopScreen />
        <FloatButtonSectionComponent />
        {isDuringAsakusaEvent && <FloatAsakusaEventSectionComponent />}
      </main>
    </>
  );
};
export default Home;
